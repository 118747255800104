import React from 'react'
import GetInTouch from './GetInTouch'
import WhyChooseUs from './WhyChooseUs'
import Process from './Process'
import ThatIsAllAboutMe from './ThatIsAllAboutMe'
import { Box } from '@mui/material'

const About = () => {
  return (
    <Box sx={{mt:16}}>
      <GetInTouch/>
      <WhyChooseUs/>
      <Process/>
      <ThatIsAllAboutMe/>
    </Box>
  )
}

export default About