import React, { useRef, useState } from 'react'
import { ThemeProvider, useTheme } from '@emotion/react'
import {  Typography, Grid, Button, Box } from '@mui/material';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SouthIcon from '@mui/icons-material/South';
import VidoePlayer from './VideoPlayer';

// icons


// export const Banner = () => {

//     // getting theme
//     const theme = useTheme();

//   return (
//     <ThemeProvider theme={theme}>
//       <Container>
//         <Grid container>
//               <Grid item xs={12} sm={12} md={6} lg={6}>
//                 <Typography sx={{mt:10}}></Typography>
//                   <Typography variant="poster" color={theme.palette.warning.main} sx={{mt:10,letterSpacing:3}}>BEAUTIFULL INVESTMENT</Typography>
//                   <Typography variant="h2" >Every one Deserves </Typography>
//                   <Typography variant='h2'>the oppertunity of</Typography>
//                   <Typography variant='h2'>the Home</Typography>
//                   <Typography variant='p' component="p" sx={{mt:2}}>We provide better solution foide better solution foide<br/> better solution for your realstate solution with the help of AI.</Typography>
//                   <Stack direction="row" sx={{mt:5}}>
//                     <Button variant='contained'>View Property</Button>  
//                   </Stack>
//               </Grid>
//               <Grid item xs={12} sm={12} md={6} lg={6}>
//                 <Image 
//                   src="/images/banner.jpg" 
//                   height="70vh" 
//                   showLoading 
//                   errorIcon={<ErrorIcon sx={{fontSize:400}}/>} 
//                   shift="left"
//                   easing='ease-in-out'
//                   sx={{borderRadius:100, mt:10}}
//                   />
//               </Grid>
//         </Grid>
//       </Container>

     
//     </ThemeProvider>
//   )
// }


export const Banner = ()=>{

  const theme = useTheme();
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);
  
  const handlePlayPause = ()=>{
    if (isPlaying){
      videoRef.current.pause();
    }else{
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };


  return(
    <ThemeProvider theme={theme}>  
      <Box sx={{position:"relative", top:{xs:50,sm:-50}, left:0, width:'100%', height:'100%'}}>
        <VidoePlayer videoRef={videoRef} />
        <Box sx={{position:"absolute",pt:{xs:10,sm:20,md:30,lg:40},top:0,left:0,width:"100%",height:"100%",backgroundColor:'rgba(0,0,0,0.6)'}}>
          <Grid container justifyContent="center" alignItems="center" spacing="2" flexDirection="column"> 

            <Typography variant="h3" color="inherit" sx={{color:"#fff",fontSize:{xs:18,sm:25,md:52}}}>Welcome to Paradise Consulting</Typography>
            <Typography variant='p' component="p" color="inherit" sx={{color:"#fff", mt:{xs:1,sm:3}, fontSize:{xs:10,sm:15,md:20}}}>Your Trusted Partner in Real Estate Solutions</Typography>
  
            
            
             
              <Button variant='contained' onClick={handlePlayPause} sx={{backgroundColor:"transparent",mt:{xs:0,sm:1},borderRadius:50, p:{xs:3,sm:3}}} disableElevation>
                  {isPlaying ?
                    <PauseIcon/>
                  : <PlayArrowIcon/>}
                </Button>
                <Typography variant='p' component="p" sx={{color:"#fff",mt:{xs:0,sm:2},fontSize:{xs:20}}}> Scroll To Explore </Typography>
                <SouthIcon sx={{color:"#fff",fontSize:{xs:20,sm:30,md:50},mt:{xs:1,sm:2,md:4}}}/>
     
          </Grid>

        </Box>
      </Box>  
    </ThemeProvider>
  );
}