import React from 'react'
import { Box, Button, Container, Grid, ThemeProvider, Typography, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'


const footerItems =[
    {
        id:1,
        title:"About Us",
        content:[
            {
                id:1.1,
                title:" Paradise is your trusted real estate consulting firm, helping you find the perfect property that suits your needs and lifestyle. We pride ourselves on providing exceptional service and valuable insights into the real estate market."
            }
        ]
    },
    {
        id:2,
        title:"Quick Links",
        content:[
            {
                id:2.1,
                title:"Properties",
                link:"/"
            },
            {
                id:2.2,
                title:"Services",
                link:"/"
            },
            {
                id:2.3,
                title:"About Us",
                link:"/"
            },
            {
                id:2.4,
                title:"Contact",
                link:"/"
            }
        ]
    },
    {
        id:3,
        title:"Contact",
        content:[
            {
                id:3.1,
                title:"Address: 123 Real Estate Ave, Indore, MP, India Email: info@paradiserealestate.com"
            }
        ]
    }
]


const Footer = () => {

    const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>

    <Box>
        <Container maxWidth="xxlg" sx={{bgcolor:"#000", color:"#fff"}}>
            <Grid container >
                {footerItems.map((items, key)=>(
                    <Grid item xs={12} sm={6} md={6} lg={4} key={key+100} sx={{p:10, textAlign:"center"}}>
                        <Typography varian="h5" mb={2} mt={2} sx={{fontWeight:"bold"}}>
                            {items.title}
                        </Typography>
                        {items.content.map((item2, key)=>(
                            <Box key={key}>
                                <Button 
                                    component={Link} 
                                    to={item2.link}
                                    sx={{textTransform:"none"}}
                                    >
                                        {item2.title}
                                    </Button>
                            </Box>
                        ))}
                    </Grid>
                ))}
            </Grid>
        </Container>
    </Box>
    </ThemeProvider>
   
    )
}

export default Footer