import { ThemeProvider, useTheme, Container, Typography, Grid } from '@mui/material'
import Image from 'mui-image';
import React from 'react'

//icons
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AllOutIcon from '@mui/icons-material/AllOut';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';


const Process = () => {
    const theme = useTheme();

    const items = [
        {
            
            icon:<AlignVerticalCenterIcon sx={{fontSize:60,color:theme.palette.primary.main}}/>,
            title:"Discovery",
            content:"Our process starts with a comprehensive consultation where we take the time to understand your specific real estate needs, preferences, and budget. This allows us to create a tailored approach that aligns with your vision. Whether you're looking for a home or investment property, our goal is to ensure we start with a clear understanding of what matters most to you."
        },
        {
            
            icon:<AllInclusiveIcon sx={{fontSize:60,color:theme.palette.success.main}}/>,
            title:"Tailored Property Search",
            content:"Based on your unique criteria, we conduct thorough market research to identify the best properties available. Our team leverages its expertise and deep market insights to provide you with personalized property recommendations. We focus on options that meet both your short-term goals and long-term investment potential."
        },
        {
            
            icon:<AllOutIcon sx={{fontSize:60,color:theme.palette.error.main}}/>,
            title:"Property Visits & Evaluation",
            content:"We arrange property viewings for you to get a firsthand experience of the shortlisted options. During these visits, we provide professional guidance, highlighting the strengths of each property while offering detailed comparisons. This helps you make well-informed decisions based on your personal preferences and market value."
        },
        {
            
            icon:<AppRegistrationIcon sx={{fontSize:60,color:theme.palette.warning.main}}/>,
            title:"Negotiation & Paperwork",
            content:"Our skilled team takes charge of negotiations, working diligently to secure the best deal on your behalf. We ensure all terms are favorable and transparent. Once the deal is agreed upon, we handle all the paperwork, from contracts to legal formalities, ensuring a smooth and seamless transaction process."
        },
        {
            
            icon:<AutoGraphIcon sx={{fontSize:60,color:theme.palette.info.main}}/>,
            title:"Final Purchase & Support",
            content:"Even after the purchase is finalized, our support continues. We assist with any remaining formalities, such as documentation, moving logistics, or additional services, ensuring you transition effortlessly into your new property. Our commitment is to provide ongoing support to make your experience stress-free and enjoyable."
        },
        
        
        
        
    ]

  return (
    <ThemeProvider theme={theme}>
        <Container maxWidth="lg">

        <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Container sx={{mt:20}}>
                    <Typography variant="h3" component="p">
                        Process
                    </Typography>
                    <Typography variant='p' component="p" sx={{fontSize:14,mt:3 }}>
                    At Paradise Consulting, we follow a streamlined process to ensure a smooth real estate experience from start to finish. We begin with a thorough consultation to understand your needs and preferences, followed by extensive market research to identify the best properties for you. Our team guides you through property selection, negotiations, and final transactions, providing support every step of the way to make your journey hassle-free.
                    </Typography>
                    <Image
                        src="/uploads/images/process.jpg"
                        sx={{mt:5,borderRadius:5}}
                    ></Image>
                </Container>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Container sx={{mt:15}}>
                    {items.map((item, key)=>
                        <Grid container sx={{mb:10}}>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                {item.icon}
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} lg={10}>
                                <Typography variant='h5' component="p">
                                    {item.title}
                                </Typography>
                                <Typography variant='p' component="p" sx={{fontSize:15,color:"#808080"}}>
                                    {item.content}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    
                 
                </Container>
            </Grid>
        </Grid>
        </Container>

    </ThemeProvider>
  )
}

export default Process