import React, { useState, useEffect } from 'react'
import { Box, CssBaseline, AppBar, Toolbar, IconButton, Typography, Button, Container, useTheme, Drawer, ListItem, List, ListItemButton, ListItemText, ThemeProvider} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Link, useLocation } from 'react-router-dom';
import Image from 'mui-image';

const drawerWidth = 350;

const navItems = [
    {
        key:1,
        text:"Home",
        link:"/"
    },
    {
        key:2,
        text:"About",
        link:"/about"
    },
    {
        key:3,
        text:"Contact",
        link:"/contact"
    },
    
]


const Navbar = (props) => {
   
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();
    
    const handleDrawerToggle = ()=>{
        setMobileOpen((prevState)=> !prevState)
    }

    const [scrolled, setScrolled] = useState(false);
    const [isHomePage, setIsHomePage] = useState(false);

    const location = useLocation();

    useEffect(()=>{
        const handleScroll = ()=>{
            if (window.scrollY > 400){
                setScrolled(true)
            }else{
                setScrolled(false)
            }
        }
        const checkHomePage = ()=>{

            if (location.pathname === '/'){
                setIsHomePage(true);
            }else{
                setIsHomePage(false);
            }
        }        
        window.addEventListener('scroll',handleScroll)
        window.addEventListener('popstate', checkHomePage)
        checkHomePage();

        return ()=>{
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('popstate',checkHomePage);
       
        }
    },[location]);
    
    const backgroundColor = scrolled || !isHomePage ? 'rgba(255,255,255,0.95)' : 'transparent';
    const fontColor = scrolled || !isHomePage ? 'black' : 'white';
    const scrollElevation = scrolled ? 2:"none";
  
    // Defining Drawer
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{textAlign:'center', display:"block"}}>

            <img src="/uploads/images/banner.jpeg" alt="banner.jpeg" width="100%" sx={{p:4}}/>
            
            <List>
                {navItems.map((item)=>(
                    <ListItem key={item.key}>
                        <ListItemButton sx={{textAlign:"center"}} to={item.link} component={Link}>
                            <ListItemText primary={item.text}></ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    )

   

    return (
        <ThemeProvider theme={theme}><br/>
            <Box >
                <CssBaseline/>
                <AppBar position='fixed' sx={{backgroundColor:backgroundColor,zIndex:2}} elevation={scrollElevation}>
                    <Container maxWidth="md">
                        <Toolbar>
                            <IconButton 
                                size="large"
                                color="#000" 
                                aria-label='open drawer' 
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                edge="start"
                                onClick={handleDrawerToggle} 
                                sx={{ display:{sm:"none"}}}>
                                <MenuIcon/>
                            </IconButton>
                            <Typography 
                                variant="h5" 
                                noWrap
                                component="a" 
                                href="/"
                                color={fontColor} 
                                sx={{
                                    mt:1,
                                    mb:1,
                                    mr:20,
                                    flexGrow:1, 
                                    display:{xs:'block',sm:'block'},
                        
                                    textDecoration:"none",
                                    transition:"0.5s all",
                                    "&:hover":{
                                        letterSpacing:2,
                                        transition:"0.5s all"
                                    }
                                    }}>
                            <Image 
                            src='/uploads/images/banner-navbar.jpeg'
                            showLoading
                            easing='ease-in-out'
                            width="150px"
                            sx={{
                                borderRadius:30,
                                mt:2
                            }}
                        ></Image>
                            </Typography>
                   
                            <Box sx={{ display:{xs:'none',sm:'block'}}}>
                                {navItems.map((item)=>(
                                    
                                    <Button 
                                        key={item.key} 
                                        component={Link}
                                        to={item.link}
                                        sx={{color:fontColor, textTransform:"none"}}>{item.text}</Button>
                                ))}
                            </Box>
                    
                </Toolbar>
                     
                
            </Container>
            
        </AppBar>
        <Box component="nav">
            <Drawer 
                variant='temporary' 
                open={mobileOpen} 
                onClose={handleDrawerToggle} 
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: {xs:'block', sm:'none'},
                    '& .MuiDrawer-paper': {boxSizing:'border-box', width:drawerWidth}
                }}>
                    {drawer}
            </Drawer>
        </Box>
      </Box>
      <br/>
    </ThemeProvider>
  )
}

export default Navbar