import { Container, ThemeProvider, Typography, useTheme, Grid, TextField, Box, FormControl, FormLabel, FormControlLabel, Checkbox, FormGroup, Button } from '@mui/material'
import React from 'react'

const GetInTouch = () => {
    const theme = useTheme()

    const checkBoxItem = [
        {
            id:1,
            label1:"Property Buying and selling Assistance",
            label2:"Investment Consulting"
        },
        {
            id:2,
            label1:"Property Management",
            label2:"Market Research and Analysis"
        },
        {
            id:3,
            label1:"Property Valuation",
            label2:"Real State Consulting"
        },
        {
            id:4,
            label1:"Legal and Documentation Assistance",
            label2:"Home Staging & Design Consultation"
        },
        {
            id:5,
            label1:"Relocation Services",
            label2:"Affordable Housing Solutions"
        },
    ]
  return (
   <ThemeProvider theme={theme}>
    <Container>

        <Typography variant='h3' component="h3">
            Get in touch
        </Typography>
        <Typography variant='p' component="p" sx={{mt:3,textAlign:'justify'}}>
        Connecting with us is easy—simply reach out via phone, email, or our website, and we’ll respond promptly. Whether you're seeking real estate advice, looking for your next property, or just exploring options, our team is here to provide personalized support. Let’s start the conversation and guide you toward your perfect real estate solution!
        </Typography>

        <Box component="form" noValidate autoComplete="off" sx={{mt:5}}>
            <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            required
                            label="First Name"
                            fullWidth
                            >

                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                                required
                                label="Last Name"
                                fullWidth
                                >

                            </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Email"
                            fullWidth
                        >

                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Phone Number"
                            fullWidth
                        >

                        </TextField>
                    </Grid>  
                    <Grid item xs={12}>
                        {/* <Typography variant='p' component="p">Services</Typography> */}

                        <FormControl>
                            <FormLabel component="legend">Services</FormLabel>
                            <FormGroup>
                                <Grid container>
                                    {checkBoxItem.map((item, key)=><>
                                        <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox></Checkbox>
                                            }
                                            label={
                                                <Typography variant='p' component="p" sx={{fontSize:12}}>
                                                    {item.label1}
                                                </Typography>}
                                         
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox></Checkbox>
                                            }
                                            label={
                                                <Typography variant='p' component="p" sx={{fontSize:12}}>
                                                    {item.label2}
                                                </Typography>}
                                        />
                                    </Grid>
                                    </>)}
                                </Grid>
                          
                                   

                            </FormGroup>

                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant='contained' fullWidth>Send Message</Button>
                    </Grid>
                    
            </Grid>
        </Box>
    </Container>
   </ThemeProvider>
  )
}

export default GetInTouch