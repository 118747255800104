import { Button, Container, ThemeProvider, Typography, useTheme } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';

const ThatIsAllAboutMe = () => {
    const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
        <Container maxWidth="xlg" sx={{textAlign:"center",p:10, backgroundColor:"#FFFAF0"}}>
            <Typography variant="h3" component="h3" sx={{letterSpacing:3}}>
                That's all about Us
            </Typography>
            <Typography variant="h3" component="h3" sx={{letterSpacing:3}}>
                feel free to say Hi.
            </Typography>
            <Typography variant='p' component="p" sx={{mt:4, letterSpacing:1}}>
                We are excited about the oppertunity to learn more aboute your buisness and how we  
            </Typography>
            <Typography variant='p' component="p" sx={{letterSpacing:1}}>
            can help you to achieve your goals in the digital about.
            </Typography>
            <Button variant='outlined' sx={{mt:6,textTransform:"capitalize",pl:4,pr:4,pt:2,pb:2,borderRadius:2}} component={Link} to="/contact">
                Get in Touch 
            </Button>
        </Container>
    </ThemeProvider>
  )
}

export default ThatIsAllAboutMe