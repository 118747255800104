import { Container, Grid, } from '@mui/material'
import React from 'react'
import GetInTouch from './GetInTouch'
import Map from './Map'

const Contact = () => {


  return (
      <Container sx={{mt:15,mb:15}}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <GetInTouch/>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Map/>
          </Grid>
        </Grid>
      </Container>

  )
}

export default Contact