import React from 'react'
import { Button, Container, Grid, ThemeProvider, Typography, useTheme } from '@mui/material'
import Image from 'mui-image'
import { Link } from 'react-router-dom'

const GetInTouch = () => {
    const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
        <Container sx={{textAlign:"center"}}>
            <Typography variant="h3" component="p">
                About Us
            </Typography>
            <Typography variant='p' component="p" sx={{fontSize:14, fontStyle:'oblique',mt:5 }}>
                At Paradise Consulting, we specialize in personalized real estate solutions, blending creative design, stylish experiences, and thorough product research. With affordable pricing and a seamless approach, we help you find the perfect property to match your vision and budget.
            </Typography>
        </Container>
        <Container sx={{mt:10}}>
            <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Image 
                        src="/uploads/images/about.jpg"
                        sx={{pl:3,pr:3, borderRadius:10}}
                        >

                    </Image>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant='p' component="p" sx={{textAlign:"justify",mt:{xs:5,sm:0}}}>
                        Paradise Consulting is your trusted partner for comprehensive real estate solutions, offering expert guidance to help you find the perfect property. We pride ourselves on combining creative design, stylish living experiences, and thorough market research to ensure you make informed decisions. Whether you're looking for residential, commercial, or investment properties, our team is dedicated to bringing your vision to life with tailored solutions.
                    </Typography>
                    <Typography variant='p' component="p" sx={{mt:4, textAlign:"justify"}}>
                        We provide affordable pricing without compromising on quality, making premium real estate accessible to everyone. Our seamless and personalized approach guarantees a stress-free journey from consultation to the final purchase. With Paradise Consulting, you can confidently take the next step toward owning your dream property.
                    </Typography>
                    <Button variant='outlined' sx={{mt:6,textTransform:"capitalize",pl:4,pr:4,pt:2,pb:2,borderRadius:2}} component={Link} to="/contact">
                        Get in Touch
                    </Button>
                </Grid>

            </Grid>
        </Container>
    </ThemeProvider>
  )
}

export default GetInTouch