import { Container, Typography, Grid, Button, Box, useTheme, ThemeProvider } from '@mui/material'
import Image from 'mui-image'
import React from 'react'

//icon
import ErrorIcon from '@mui/icons-material/Error';
import { Link } from 'react-router-dom';

const OurWork = () => {

    const items = [
        {
            id:1,
            h4Left:"Featured Properties",
            h3Left:"Success Stories in Real Estate",
            h5Left:"Diverse Real Estate Success",
            pLeft:"At Paradise Consulting, we take pride in delivering exceptional properties that meet our clients' needs and dreams. From luxury homes to commercial spaces, our portfolio showcases a diverse range of successful real estate transactions. Here are some of the standout properties we’ve helped our clients find, sell, or invest in. Luxury Apartments in Indore, Commercial Space in Bhopal, Residential Villas in Ujjain",
            linkLeft:"/",
            imageSrcLeft:"/uploads/images/featured-properties-1.jpg",
            shiftLeft:"left",
            h4Right:"Our Approach",
            h3Right:"A Step-by-Step Process to Property Ownership",
            h5Right:"A Tailored Path for Every Client",
            pRight:"We believe in making the real estate journey seamless and stress-free. Our process involves in-depth consultations, tailored property searches, and expert guidance through every step. Our approach combines market insights, attention to detail, and clear communication to ensure every client walks away satisfied.",
            linkRight:"/",
            imageSrcRight:"/uploads/images/our-approach-1.jpg",
            shiftRight:"right"
        },
        {
            id:2,
            h4Left:"Design & Development",
            h3Left:"Transforming Vision into Reality",
            h5Left:"Creative and Custom Solutions",
            pLeft:"At Paradise Consulting, we are not just about finding properties; we bring creative design and development into every project. From initial concept to final design, we collaborate with architects and builders to create unique living and commercial spaces. Whether it’s interior design consultation or full-scale property development, our work turns vision into reality.",
            linkLeft:"/",
            imageSrcLeft:"/uploads/images/design-1.jpg",
            shiftLeft:"left",
            h4Right:" Research & Innovation",
            h3Right:"Data-Driven Decisions for Maximum Value",
            h5Right:"Expert Market Analysis",
            pRight:"Our success stems from the extensive research and innovation we apply to every project. We analyze market trends, property value fluctuations, and investment opportunities to ensure you’re always making the right decision. Our deep industry knowledge allows us to anticipate shifts in the market, providing our clients with the edge they need.",
            linkRight:"/",
            imageSrcRight:"/uploads/images/research-1.jpg",
            shiftRight:"right"
        },
        
    ]
    const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>

    <Container sx={{mt:15, mb:15}}>
        <Typography variant='h3' align='center'>Our Work</Typography>
            <Box sx={{display:{xs:"none",sm:"block"}}}>
                {items.map((item)=>(
            
                <Grid container sx={{mt:10}}>
                    <Grid item xs={12} sm={6} md={6} lg={6} align="left">
                        <Container sx={{p:10}}>
                            <Typography variant='h4' sx={{color:theme.palette.secondary.light}}>{item.h4Left}</Typography>
                            <Typography variant='h3' >{item.h3Left}</Typography>
                            <Typography variant='h5' sx={{color:theme.palette.secondary.light}}>{item.h5Left}</Typography>
                            <Typography variant='p' component="p" sx={{mt:5,mb:5,textAlign:"justify"}}>{item.pLeft}</Typography>
                            {/* <Button variant="text" component={Link} style={{textDecoration:"underline"}} to={item.linkLeft}>Read More.</Button> */}
                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} align="right">
                        <Container sx={{p:10}}>
                            <Image 
                                src={item.imageSrcLeft}
                                showLoading
                                errorIcon={<ErrorIcon sx={{fontSize:100}}/>}
                                shift={item.shiftLeft}
                                distance={100}
                                style={{borderRadius:16}}
                                sx={{boxShadow:10}}
                            />
                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Container sx={{p:10}}>
                            <Image 
                                    src={item.imageSrcRight}
                                    showLoading
                                    errorIcon={<ErrorIcon sx={{fontSize:100}}/>}
                                    shift={item.shiftRight}
                                    distance={100}
                                    style={{borderRadius:16}}
                                    sx={{boxShadow:10}}
                                />
                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Container sx={{p:10}}>
                            <Typography variant='h4' sx={{color:theme.palette.secondary.light}}>{item.h4Right}</Typography>
                            <Typography variant='h3'>{item.h3Right}</Typography>
                            <Typography variant='h5' sx={{color:theme.palette.secondary.light}}>{item.h5Right}</Typography>
                            <Typography variant='p' component="p" sx={{mt:5,mb:5,textAlign:"justify"}}>{item.pRight}</Typography>
                            {/* <Button variant="text" component={Link} style={{textDecoration:"underline"}} to={item.linkRight}>Read More.</Button> */}
                        
                        </Container>
                    </Grid>
                </Grid>
            
                
                ))}
            </Box>
            <Box sx={{display:{xs:"block",sm:"none"}}}>
                {items.map((item)=>(
            
                <Grid container sx={{mt:10}}>
                     <Grid item xs={12} sm={6} md={6} lg={6} >
                        <Container sx={{p:5}}>
                            <Image 
                                src={item.imageSrcLeft}
                                showLoading
                                errorIcon={<ErrorIcon sx={{fontSize:100}}/>}
                                shift={item.shiftLeft}
                                distance={100}
                                style={{borderRadius:16}}
                                sx={{boxShadow:10}}
                            />
                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} >
                        <Container sx={{p:5}}>
                            <Typography variant='h4'>{item.h4Left}</Typography>
                            <Typography variant='h3'>{item.h3Left}</Typography>
                            <Typography variant='h5'>{item.h5Left}</Typography>
                            <Typography variant='p' component="p" sx={{mt:5,mb:5,textAlign:"justify"}} >{item.pLeft}</Typography>
                            <Button variant="text" component={Link} style={{textDecoration:"underline"}} to={item.linkLeft}>Read More.</Button>
                        </Container>
                    </Grid>
                   
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Container sx={{p:5}}>
                            <Image 
                                    src={item.imageSrcRight}
                                    showLoading
                                    errorIcon={<ErrorIcon sx={{fontSize:100}}/>}
                                    shift={item.shiftRight}
                                    distance={100}
                                    style={{borderRadius:16}}
                                    sx={{boxShadow:10}}
                                />
                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Container sx={{p:5}}>
                            <Typography variant='h4'>{item.h4Right}</Typography>
                            <Typography variant='h3'>{item.h3Right}</Typography>
                            <Typography variant='h5'>{item.h5Right}</Typography>
                            <Typography variant='p' component="p" sx={{mt:5,mb:5,textAlign:"justify"}}>{item.pRight}</Typography>
                            <Button variant="text" component={Link} style={{textDecoration:"underline"}} to={item.linkRight}>Read More.</Button>
                        
                        </Container>
                    </Grid>
                </Grid>
            
                
                ))}
            </Box>
            <Box sx={{textAlign:"center", mt:10}}>
                <Typography variant='p' component="p" >Don’t just take our word for it—hear what our satisfied clients have to say! From first-time home buyers to seasoned investors, we have helped countless people achieve their real estate goals. See why Paradise Consulting is the trusted name in real estate for so many.</Typography>
                <Typography variant='p' component="p" sx={{mt:10, fontStyle:"italic"}}>"Thanks to Paradise Consulting, we found our perfect home quickly and without stress. Their team was amazing"</Typography>
                <Typography variant='caption' component="p">- The Sharma Family</Typography>
                <br/>
                <Typography variant='p' component="p" sx={{mt:5, fontStyle:"italic"}}>"Their professionalism and market knowledge helped us secure a great commercial property at an unbeatable price."</Typography>
                <Typography variant='caption' component="p">- Bhopal Interprise</Typography>
                
            </Box>
    </Container>
    </ThemeProvider>
    
  )
}

export default OurWork