import React from 'react'
import { ThemeProvider, useTheme } from '@emotion/react'
import { Banner } from './Banner';
import Passion from './Passion';
import OurWork from './OurWork';
import ContactUs from './ContactUs';
import Gallery from './Gallery';
import Testimonial from './Testimonial';
import BuildingIcons from './BuildingIcons';

const Home = () => {
  
  // Getting Theme which are using allready
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <Banner/>
      <BuildingIcons />
      <Passion/>
      <OurWork/>
      <ContactUs/>
      <Gallery/>
      <Testimonial/>
 
    </ThemeProvider>
  )
}

export default Home