import { createTheme, responsiveFontSizes } from "@mui/material"
import { blueGrey, grey, pink, amber, cyan, deepPurple,} from "@mui/material/colors"

const lightTheme = createTheme({
    palette:{
        primary:{
            main:blueGrey[500],
            light:blueGrey[300],
            dark:blueGrey[700],
            contrastText:"#fff"
        },
        secondary:{
            main:grey[600],
            light:grey[400],
            dark:grey[900],
            contrastText:grey[50]
        },
        error:{
            main:pink[700],
            light:pink[500],
            dark:pink[900],
            contrastText:pink[50]
        },
        warning:{
            main:amber[700],
            light:amber[200],
            dark:amber[900],
            contrastText:amber[50]
        },
        info:{
            main:cyan[600],
            light:cyan[300],
            dark:cyan[800],
            contrastText:cyan[50]
        },
        success:{
            main:deepPurple[500],
            light:deepPurple[200],
            dark:deepPurple[700],
            contrastText:deepPurple[50]
        }
    },
    typography:{
        fontFamily:"Montserrat"
    }
})

export const responsiveLightTheme = responsiveFontSizes(lightTheme)



