import React from 'react'
import { Grid, Container, Typography } from '@mui/material'
import { ThemeProvider, useTheme } from '@emotion/react'
import Image from 'mui-image'

import ErrorIcon from '@mui/icons-material/Error';

const BuildingIcons = () => {
    const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
        <Container sx={{mt:20}}>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <center>
                        <Image 
                            src='/uploads/images/banner.jpeg'
                            showLoading
                            errorIcon={<ErrorIcon sx={{fontSize:200}}/>}
                            easing='ease-in-out'
                            sx={{
                                width:"100px",
                                objectFit:'cover',
                                p:10
                            }}
                        ></Image>
                    </center>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Container>
                        <Typography variant='h5'>An Inspiring Odyssey of Unmatched Excellence and Growth</Typography><br/>
                        <Typography variant='p' sx={{fontSize:13}}>At Paradise Consulting, we have been delivering exceptional quality and building lasting relationships with our clients for over a decade. As a leading name in real estate consulting in Madhya Pradesh, Paradise Consulting has earned a reputation for developing premium yet affordable properties that cater to a wide range of needs. Explore our stunning upcoming projects across the region or check out our latest ventures in prime locations.</Typography><br/>
                        <Typography variant='p' sx={{fontSize:13}}>Our focus lies in creating spaces that are not only stylish but set new benchmarks in design and functionality. The success of Paradise Consulting stems from our unwavering dedication to customer satisfaction and our readiness to go above and beyond for each client. We are committed to excellence and strive to offer a seamless, personalized experience for every homebuyer. Discover more about us online: Paradise Consulting, Madhya Pradesh.</Typography>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    </ThemeProvider>
  )
}

export default BuildingIcons