import { Container, Typography, Paper, Grid /*styled*/, Box } from '@mui/material'
import Image from 'mui-image'
import React from 'react'
import Carousel from 'react-material-ui-carousel'
// import FavoriteIcon from '@mui/icons-material/Favorite';

// icons
import ErrorIcon from '@mui/icons-material/Error';
import Rating from '@mui/material/Rating';


// const styledRating = styled(Rating)({
//   '& .MuiaRating-iconFilled':{
//     color:"#ff6d75",
//   },
//   '& .MuiRating-iconHover': {
//     color: '#ff3d47',
//   },
// });


function Item(props)
{
    return (
        <Paper elevation={0} sx={{p:10}}>
          <Typography variant='h3' component="p">
            {props.item.name}
          </Typography>
      
          <Rating 
            name="size-small" 
            defaultValue={props.item.rating} 
            size="small" 
            sx={{mt:1}} 
            readOnly
            />

            <Typography variant='p' component="p" sx={{mt:3}}>
              <i>"{props.item.description}"</i>
            </Typography>

          
        </Paper>
    )
}


const Testimonial = () => {
    var items = [
      {
        name: "Ravi Sharma",
        rating:4.5,
        description: "Paradise made the process of buying my first home seamless and stress-free. Their expertise and dedication are unmatched. Highly recommend them! They took the time to understand my needs and found the perfect match. I couldn't be happier with the service provided."
    },
    {
        name: "Anjali Mehta",
        rating:4,
        description: "The team at Paradise truly cares about their clients. They helped me find the perfect property and handled all the paperwork efficiently. Great service! They were always available to answer my questions and provided valuable advice. I felt confident throughout the entire process."
    },
    {
      name: "Amit Desai",
      rating:4.7,
      description: "Working with Paradise was a fantastic experience. They understood my needs and found the ideal property for my family. Their professionalism is top-notch. The entire process was smooth and hassle-free. I would definitely recommend them to anyone looking for real estate services."
    },
    {
      name: "Priya Patel",
      rating:5,
      description: "Paradise provided excellent service and valuable insights into the real estate market. They were patient and attentive to all my requirements. Very satisfied! They made sure I was comfortable with every decision. I truly appreciate their commitment and hard work."
    },
    {
      name: "Rahul Verma",
      rating:4,
      description: "The consultants at Paradise are knowledgeable and friendly. They guided me through every step of selling my property and got me a great deal. Highly recommended! Their market analysis was spot-on, and they negotiated effectively on my behalf. I couldn't have asked for a better experience."
    },
    {
      name: "Sneha Nair",
      rating:5,
      description: "Paradise exceeded all my expectations when it came to finding my dream home. Their attention to detail and personalized approach was impressive. They walked me through every step and made sure I was comfortable throughout. Truly a wonderful experience."
    },
    {
      name: "Rohit Kumar",
      rating:3.5,
      description: "I am incredibly grateful to Paradise for their exceptional service in helping me purchase my new office space. They were professional and responsive to all my queries. The team provided valuable insights and negotiated a great deal for me. Their dedication is truly commendable."
    },
    
    ]
    
    const images = [
      {
        name:"p1",
        image:"/uploads/images/banner.jpg"
      },
      {
        name:"p1",
        image:"/uploads/images/banner.jpg"
      },
      {
        name:"p1",
        image:"/uploads/images/banner.jpg"
      },
      {
        name:"p1",
        image:"/uploads/images/banner.jpg"
      }
    ]
    return (
    <Container sx={{mt:20, mb:20}}>
        <Typography variant='h3' align='center'>Testimonial</Typography>
        <Grid container spacing={4} sx={{mt:10}}>
          <Grid item xs={12} sm={12} md={6} lg={6} >
            <Carousel indicators={false} navButtonsAlwaysInvisible={true} stopAutoPlayOnHover={false} sx={{zIndex:-1}}>
              {images.map((item,i)=><>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end', // Align image to the right
                    p:5
                  }}
                >
                <Image 
                  src={item.image}
                  height="40vh"
                  width="40vh" 
                  showLoading 
                  errorIcon={<ErrorIcon sx={{fontSize:400}}/>} 
                  shift="left"
                  easing='ease-in-out'
                  sx={{borderRadius:200, boxShadow:10}}
              
                  />   
                  </Box>       
              </>)}
            </Carousel>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Carousel indicators={false} navButtonsAlwaysInvisible={true} stopAutoPlayOnHover={false} sx={{zIndex:-1}}>
                {items.map((item,i)=><>
                  <Item key={i} item={item} />
                </>)}
              </Carousel>
          </Grid>
          
        </Grid>
        
    </Container>
  )
}

export default Testimonial