import { ThemeProvider, useTheme, Container, Typography, Grid, Box } from '@mui/material'
import React from 'react'

import AcUnitIcon from '@mui/icons-material/AcUnit';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';

const WhyChooseUs = () => {
    const theme = useTheme();

    const items = [
        {
            icon:<AcUnitIcon sx={{fontSize:50}}/>,
            title:"Expertise",
            content:"Expertise in delivering tailored real estate solutions with a blend of creativity, market insight, and exceptional value.",
        },
        {
            icon:<AccessAlarmIcon sx={{fontSize:50}}/>,
            title:"Attention to Detail",
            content:"Meticulous attention to detail, ensuring every aspect of your real estate journey is flawlessly executed.",
        },
        {
            icon:<AccessibilityIcon sx={{fontSize:50}}/>,
            title:"Communication",
            content:"Transparent and open communication, keeping you informed and empowered at every step of your real estate journey.",
        },
        {
            icon:<AddIcCallIcon sx={{fontSize:50}}/>,
            title:"Customer Serivce",
            content:"Exceptional customer service, dedicated to exceeding your expectations and ensuring a seamless real estate experience.",
        },
        
    ]

  return (
    <ThemeProvider theme={theme}>
         <Container sx={{textAlign:"center",mt:20}}>
            <Typography variant="h3" component="p">
                Why Choose Us
            </Typography>
            <Typography variant='p' component="p" sx={{fontSize:14, fontStyle:'oblique',mt:5 }}>
                At Paradise Consulting, we go beyond typical real estate services by offering personalized solutions that perfectly align with your needs. Our team brings a creative approach to property design, ensuring you experience both style and functionality. With extensive market research and industry insights, we guide you through every step, helping you make well-informed, confident decisions.
            </Typography>
        </Container>

        <Container sx={{mt:10}}>
            <Grid container>
                {items.map((item, key)=>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box sx={{p:5,backgroundColor:"#FAF9F6",m:4,borderRadius:4,boxShadow:5}}>
                            <Typography variant='p' component="p">
                                {item.icon}
                            </Typography>
                            <Typography variant='h5' component="p" sx={{mt:2}}>
                                {item.title}
                            </Typography>
                            <Typography variant='p' component="p" sx={{fontSize:13,mt:2}}>
                                {item.content}
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Container>
    </ThemeProvider>
  )
}

export default WhyChooseUs