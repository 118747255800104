import React from 'react'
import { ThemeProvider, useTheme, Box } from '@mui/material'

const Map = () => {
    const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
        <Box sx={{p:1}}>
        <iframe 
            title="location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d132037.1931445652!2d77.12658430931451!3d28.527327337742438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x52c2b7494e204dce!2sNew%20Delhi%2C%20Delhi!5e1!3m2!1sen!2sin!4v1728968480491!5m2!1sen!2sin" 
            width="100%" 
            height="700" 
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </Box>
    </ThemeProvider>
  )
}

export default Map