import React, {useEffect} from 'react'

const VidoePlayer = ({videoRef})=>{
    useEffect(()=>{
        const video = videoRef.current
        video.muted = true
        video.loop = true
        video.play()

    },[videoRef])
    
    return(
        <video ref={videoRef} style={{width:"100%", height:"100%"}}>
            <source src="uploads/video/banner.mov" type="video/mp4"></source>
        </video>
    );
}

export default VidoePlayer;