import { useTheme } from '@emotion/react'
import { Button, Container, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const ContactUs = () => {
    const theme = useTheme()

  return (
   <Container maxWidth="xxl" sx={{mt:15, mb:15, backgroundColor:theme.palette.warning.light,pt:15, pb:15}}>
        <Typography variant="h3" align='center'>Are you Ready for Move ?</Typography>
        <Typography variant="p" component="p" align='center' sx={{mt:3, letterSpacing:2}}>Ready for a change? Let us guide you to your dream space with ease and confidence!</Typography>
        <center>
            <Button 
                variant='contained' 
                sx={{
                    mt:5, 
                    backgroundColor:theme.palette.warning.light, 
                    color:theme.palette.primary.main,
                    border:1,
                    pt:1,
                    pb:1,
                    pl:4,
                    pr:4,
                    "&:hover":{
                        color:"#fff"
                    }
                    }} 
                to="/contact"
                component={Link}
                    >Contact Us</Button>
        </center>
   </Container>
  )
}

export default ContactUs