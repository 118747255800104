import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from "@mui/material";
import { responsiveLightTheme } from "./Styles/Theme";
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ThemeProvider theme={responsiveLightTheme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>

);


