import {  Container, Grid, Typography, Paper, Button, Stack } from '@mui/material'
import React from 'react'
import {styled, ThemeProvider} from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
//icon
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ScienceIcon from '@mui/icons-material/Science';
import BiotechIcon from '@mui/icons-material/Biotech';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    margin:theme.spacing(3),
    color: theme.palette.text.secondary,
    textAlign:"center",
  
  }));

const Passion = () => {

    // getting used theme which are used in global 
    const themeGlobal = useTheme()

    const data = [
        {
            id:1,
            icon:<EmojiObjectsIcon fontSize='100' sx={{color:themeGlobal.palette.error.light}}/>,
            title:"Creative Design",
            detail:"We turn your vision into reality with designs that inspire and stand out.",
            link:"/",
            color:themeGlobal.palette.error.light,
        },
        {
            id:2,
            icon:<ScienceIcon fontSize='100' sx={{color:themeGlobal.palette.warning.light}}/>,
            title:"Experiance Style",
            detail:"Where timeless style meets unparalleled experience to create spaces you’ll love.",
            link:"/",
            color:themeGlobal.palette.warning.light
        },
        {
            id:3,
            icon:<BiotechIcon fontSize='100' sx={{color:themeGlobal.palette.info.light}}/>,
            title:"Product Research",
            detail:"Our in-depth research ensures you get the best products tailored to your needs.",
            link:"/",
            color:themeGlobal.palette.info.light
        },
        {
            id:4,
            icon:<CurrencyRupeeIcon fontSize='100' sx={{color:themeGlobal.palette.success.light}}/>,
            title:"Affordable Price",
            detail:"Quality living at prices that won't break the bank—luxury within reach.",
            link:"/",
            color:themeGlobal.palette.success.light
        },
        
    ]

  return (
    <ThemeProvider theme={themeGlobal}>

        <Container sx={{mt:15}}>
            <Typography variant="h3" align='center' sx={{mb:7}}>Our Passion is </Typography>
            <Grid container>
                {data.map((item)=>(
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Item sx={{"&:hover":{boxShadow:`2px 2px 50px 1px ${item.color}`}}}>
                            <Stack direction="column" mt={2} spacing={2}>
                                <Typography variant='h1'>{item.icon}</Typography>
                            </Stack>
                            <Stack direction="column" mt={2} spacing={2}>
                                <Typography variant='h6' sx={{mb:2}} align='center'>{item.title}</Typography>
                            </Stack>
                            <Stack direction="column" mt={2} spacing={2}>
                                <Typography variant="p" sx={{fontSize:12}}>{item.detail}</Typography>
                            </Stack>
                            <Stack direction="column" mt={2} spacing={2}>
                                <Button 
                                    variant="contained"
                                    size='small'
                                    component={Link} 
                                    to={item.link} sx={{mt:5,pt:1,pb:1}}>
                                        <Typography color="#fff" fontSize={12}>
                                            Read More
                                        </Typography>
                                </Button>   
                            </Stack>        
                        </Item>
                    </Grid>
                ))}

            </Grid>
        </Container>
    </ThemeProvider>
    
  )
}

export default Passion